/* Import Files screen */
.responsive-div {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

/* Styles when the import button is disabled */
.responsive-div-import-disabled-button {
  text-align: right;
  position: relative;
  right: 20px;
  pointer-events: none;
  opacity: 0.5;
}

/* Styles when the import button is enabled */
/* .responsive-div-import-enabled-button {
  text-align: right;
  position: relative;
  right: 0px;
}*/

.sectionIcon {
  font-size: 130px;
  color: #808080;
  opacity: 0.5;
}

.sectionPara {
  color: #808080;
  opacity:0.5;
}

/* Manage Import Exceptions screen */
.responsive-div-mie {
  width:100%;
  padding-left:25px;
  padding-top:40px;
}

.responsive-div-mie-table {
  width:100%; 
  max-height:60vh;
  overflow:auto;
  padding-top: 0px;
}

/* Manage Auto Match Exceptions screen */
.responsive-div-mame {
  height: 100vh;
  margin-left:25px;
  }

  /* Manage Auto Match Exceptions Children screen */
.responsive-div-cmame {
  width:100%;
  padding-left:0px;
  padding-top:20px;
  }

.responsive-div-cmame-table {
  margin-top: 10px;
  width:100%; 
  height:30vh;
  overflow:auto;
  }

/* Reconcile screen */
.responsive-div-rec {
  margin-left:27px;
  width:100%;
}

.responsive-div-rec-table {
  width:98%;
  max-height:50vh;
  overflow:hidden auto;
  margin-top:12px;
}

/* Styles for landscape phones */
@media (min-width: 576px) and (max-width: 767.98px) { 
  .responsive-div {
    padding: 45px;
    font-size: 2.7rem;
    max-width: 1800px;
    min-height: 45vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsive-div-fileupload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
  }

  .sectionIcon {
      font-size: 120px;
      color: #808080;
      opacity: 0.5;
      margin-bottom: 10px;
  }

  .sectionPara {
      font-size: 30px;
      color: #808080;
      opacity: 0.5;
  }

  .responsive-div-table {
      width: 95%;
      overflow-y: auto;
      flex-grow: 1;
      margin-top: 10px;
      height: auto;
  }  

  .responsive-div-import-enabled-button,
  .responsive-div-import-disabled-button {
    position: relative;
    bottom: -102px;
    right: -200px;
    padding: 10px 20px;
  }

  .responsive-div-import-disabled-button {
      pointer-events: none;
      opacity: 0.5;
  }

}

/* Styles for tablets */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .responsive-div {
    padding: 45px;
    font-size: 2.7rem;
    max-width: 1800px;
    min-height: 45vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsive-div-fileupload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
  }

  .sectionIcon {
      font-size: 120px;
      color: #808080;
      opacity: 0.5;
      margin-bottom: 10px;
  }

  .sectionPara {
      font-size: 30px;
      color: #808080;
      opacity: 0.5;
  }

  .responsive-div-table {
      width: 95%;
      overflow-y: auto;
      flex-grow: 1;
      margin-top: 10px;
      height: auto;
  }  

  .responsive-div-import-enabled-button,
  .responsive-div-import-disabled-button {
    position: relative;
    bottom: -102px;
    right: -250px;
    padding: 10px 20px;
  }

  .responsive-div-import-disabled-button {
      pointer-events: none;
      opacity: 0.5;
  }

  .responsive-div-mie {
    height: 55vh;
    padding-top:20px;
    width: 100%;
    margin-left:5px;
  }

  .responsive-div-mie-table{
    width:100%; 
    max-height:37vh;
    overflow:auto;
    padding-top: 0px;
  }

  .responsive-div-mame {
    height: 60vh;
    }
  
    .responsive-div-rec-reset{
      height: 65vh;
    }
  
    .responsive-div-rec-reset-typo{
      padding-top: 80px;
    }
  
    .responsive-div-rec-reset-button{
      text-align: right;
      padding-top: 80px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
}

/* zoom on 150% */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .responsive-div {
    padding: 45px;
    font-size: 2.7rem;
    max-width: 1800px;
    min-height: 56vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsive-div-fileupload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
  }

  .sectionIcon {
      font-size: 120px;
      color: #808080;
      opacity: 0.5;
      margin-bottom: 10px;
  }

  .sectionPara {
      font-size: 30px;
      color: #808080;
      opacity: 0.5;
  }

  .responsive-div-table {
      width: 95%;
      overflow-y: auto;
      flex-grow: 1;
      margin-top: 10px;
      height: auto;
  }  
  .responsive-div-import-disabled-button {
    display: inline-flex;
    align-items: center;
    pointer-events: none;
    opacity: 0.5;
  }

  .button-container {
    top: 15px;
    position: relative;
  }

  .button-group {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 50px;
  }

  .responsive-div-import-enabled-button {
    display: inline-flex;
    align-items: center;
  }

    .responsive-div-mie {
      height: 50vh;
      padding-top:20px;
      width: 100%;
      margin-left:5px;
    }

    .responsive-div-mie-table{
      width:100%; 
      max-height:37vh;
      overflow:auto;
      padding-top: 0px;
    }

    .responsive-div-mame {
      height: 60vh;
      }

    .responsive-div-mame-table {
      margin-top: 10px;
      width:100%; 
      height:35vh;
      overflow:auto;
    }

    .responsive-div-rec-reset{
      height: 70vh;
    }
  
    .responsive-div-rec-reset-typo{
      padding-top: 100px;
    }
  
    .responsive-div-rec-reset-button {
      position: absolute;
      right: 1%;
      top: 92%;
      transform: translateY(-50%);
      text-align: right;
      padding: 20px;
      margin: 0;
    }
}

/* zoom on 110% & 125% */
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .responsive-div {
    padding: 45px;
    font-size: 2.7rem;
    max-width: 1800px;
    min-height: 70vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsive-div-fileupload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
  }

  .sectionIcon {
      font-size: 120px;
      color: #808080;
      opacity: 0.5;
      margin-bottom: 10px;
  }

  .sectionPara {
      font-size: 30px;
      color: #808080;
      opacity: 0.5;
  }

  .responsive-div-table {
      width: 95%;
      overflow-y: auto;
      flex-grow: 1;
      margin-top: 10px;
      height: auto;
  }  

  .responsive-div-import-disabled-button {
    display: inline-flex;
    align-items: center;
    pointer-events: none;
    opacity: 0.5;
  }

  .button-container {
    top: 15px;
    position: relative;
  }

  .button-group {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 50px;
  }

  .responsive-div-import-enabled-button {
    display: inline-flex;
    align-items: center;
  }

  .responsive-div-mie {
    height: 63vh;
    padding-top:20px;
    width: 100%;
    margin-left:4px;
  }

  .responsive-div-mie-table{
    width:100%; 
    max-height:52vh;
    overflow:auto;
    padding-top: 0px;
  }

  .responsive-div-mame {
    height: 64vh;
    }

    .responsive-div-mame-table {
      margin-top: 10px;
      width:100%; 
      height:45vh;
      overflow:auto;
      }

  .responsive-div-rec{
    height: 65vh;
    overflow:auto;
  }

  .responsive-div-rec-reset{
    height: 78vh;
  }

  .responsive-div-rec-reset-typo{
    padding-top: 150px;
  }

  .responsive-div-rec-reset-button {
    position: absolute;
    right: 1%;
    top: 95%;
    transform: translateY(-50%);
    text-align: right;
    padding: 20px;
    margin: 0;
  }

  .responsive-div-rec-table {
    width:95%;
    max-height:30vh;
    overflow:hidden auto;
    margin-top:12px;
  }  
}

/* zoom on 100% */
@media (min-width: 1440px) and (max-width: 1699.98px) {
  .responsive-div {
    padding: 45px;
    font-size: 2.7rem;
    max-width: 1800px;
    min-height: 75vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsive-div-fileupload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
  }

  .sectionIcon {
      font-size: 120px;
      color: #808080;
      opacity: 0.5;
      margin-bottom: 10px;
  }

  .sectionPara {
      font-size: 30px;
      color: #808080;
      opacity: 0.5;
  }

  .responsive-div-table {
      width: 95%;
      overflow-y: auto;
      flex-grow: 1;
      margin-top: 10px;
      height: auto;
  }  

  .responsive-div-import-disabled-button {
    display: inline-flex;
    align-items: center;
    pointer-events: none;
    opacity: 0.5;
  }

  .button-container {
    top: 15px;
    position: relative;
  }

  .button-group {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 50px;
  }

  .responsive-div-import-enabled-button {
    display: inline-flex;
    align-items: center;
  }

    .responsive-div-mie {
      padding-top:20px;
      height: 65vh;
      width: 100%;
      margin-left:5px;
    }

    .responsive-div-mie-table{
      width:100%; 
      max-height:55vh;
      overflow:auto;
      padding-top: 0px;
    }

    .responsive-div-mame {
      height: 67vh;
      }

      .responsive-div-mame-table {
        margin-top: 10px;
        width:100%; 
        height:50vh;
        overflow:auto;
        }

    .responsive-div-cmame-table {
      margin-top: 10px;
      width:100%; 
      height:25vh;
      overflow:auto;
      }

    .responsive-div-rec{
      height: 68vh;
    }

    .responsive-div-rec-reset{
      height: 80vh;
    }
  
    .responsive-div-rec-reset-typo{
      padding-top: 250px;
    }
  
    .responsive-div-rec-reset-button {
      position: absolute;
      right: 1%;
      top: 95%;
      transform: translateY(-50%);
      text-align: right;
      padding: 20px;
      margin: 0;
    }

    .responsive-div-rec-table {
      width:95%;
      max-height:40vh;
      overflow:hidden auto;
      margin-top:12px;
    }
}

/* zoom on 90% */
@media (min-width: 1700px) and (max-width: 1919.98px){
  .responsive-div {
    padding: 45px;
    font-size: 2.7rem;
    max-width: 1800px;
    min-height: 77vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsive-div-fileupload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
  }

  .sectionIcon {
      font-size: 120px;
      color: #808080;
      opacity: 0.5;
      margin-bottom: 10px;
  }

  .sectionPara {
      font-size: 30px;
      color: #808080;
      opacity: 0.5;
  }

  .responsive-div-table {
      width: 80%;
      overflow-y: auto;
      flex-grow: 1;
      margin-top: 10px;
      height: auto;
  }  

  .responsive-div-import-disabled-button {
    display: inline-flex;
    align-items: center;
    pointer-events: none;
    opacity: 0.5;
  }

  .button-container {
    top: 15px;
    position: relative;
  }

  .button-group {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 50px;
  }

  .responsive-div-import-enabled-button {
    display: inline-flex;
    align-items: center;
  }

  .responsive-div-mie {
    padding-top:20px;
    height: 67vh;
    width: 100%;
    margin-left:5px;
  }

  .responsive-div-mie-table{
    width:100%; 
    max-height:57vh;
    overflow:auto;
    padding-top: 0px;
  }

  .responsive-div-mame {
    height: 70vh;
    }

    .responsive-div-mame-table {
      margin-top: 10px;
      width:100%; 
      height:55vh;
      overflow:auto;
      }

  .responsive-div-cmame-table {
    margin-top: 10px;
    width:100%; 
    height:25vh;
    overflow:auto;
    }

  .responsive-div-rec{
    height: 70vh;
  }

  .responsive-div-rec-reset{
    height: 82vh;
  }

  .responsive-div-rec-reset-typo{
    padding-top: 270px;
  }

  .responsive-div-rec-reset-button {
    position: absolute;
    right: 1%;
    top: 95.5%;
    transform: translateY(-50%);
    text-align: right;
    padding: 20px;
    margin: 0;
  }

  .responsive-div-rec-table {
    width:95%;
    max-height:40vh;
    overflow:hidden auto;
    margin-top:12px;
  }
}

/* zoom on 80% */
@media (min-width: 1920px) and (max-width: 1999.98px) {
  .responsive-div {
    padding: 45px;
    font-size: 2.7rem;
    max-width: 1800px;
    min-height: 80vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsive-div-fileupload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
  }

  .sectionIcon {
      font-size: 120px;
      color: #808080;
      opacity: 0.5;
      margin-bottom: 10px;
  }

  .sectionPara {
      font-size: 30px;
      color: #808080;
      opacity: 0.5;
  }

  .responsive-div-table {
      width: 85%;
      overflow-y: auto;
      flex-grow: 1;
      margin-top: 10px;
      height: auto;
  }  

  .responsive-div-import-disabled-button {
    display: inline-flex;
    align-items: center;
    pointer-events: none;
    opacity: 0.5;  }

  .button-container {
    position: relative;
    top: 15px;
  }

  .button-group {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 50px;
  }

  .responsive-div-import-enabled-button {
    display: inline-flex;
    align-items: center;
  }

  .responsive-div-mie {
    padding-top:20px;
    height: 71vh;
    width: 100%;
    margin-left:5px;
  }

  .responsive-div-mie-table{
    width:100%; 
    max-height:63vh;
    overflow:auto;
    padding-top: 0px;
  }

  .responsive-div-mame {
    height: 73vh;
    }

    .responsive-div-mame-table {
      margin-top: 10px;
      width:100%; 
      height:60vh;
      overflow:auto;
      }

  .responsive-div-cmame-table {
    margin-top: 10px;
    width:100%; 
    height:22vh;
    overflow:auto;
    }

  .responsive-div-rec{
    height: 74vh;
  }

  .responsive-div-rec-reset{
    height: 84vh;
  }

  .responsive-div-rec-reset-typo{
    padding-top: 300px;
  }

  .responsive-div-rec-reset-button {
    position: absolute;
    right: 1%;
    top: 96%;
    transform: translateY(-50%);
    text-align: right;
    padding: 20px;
    margin: 0;
  }

  .responsive-div-rec-table {
    width:96%;
    max-height:50vh;
    overflow:hidden auto;
    margin-top:12px;
  }
}

/* zoom on 75% */
@media (min-width: 2000px) and (max-width: 2099.98px) {
    .responsive-div {
      padding: 45px;
      font-size: 2.7rem;
      max-width: 1800px;
      min-height: 81vh;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .responsive-div-fileupload {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
    }

    .sectionIcon {
        font-size: 120px;
        color: #808080;
        opacity: 0.5;
        margin-bottom: 10px;
    }

    .sectionPara {
        font-size: 30px;
        color: #808080;
        opacity: 0.5;
    }

    .responsive-div-table {
        width: 90%;
        overflow-y: auto;
        flex-grow: 1;
        margin-top: 10px;
        height: auto;
    }  

    .responsive-div-import-disabled-button {
      display: inline-flex;
      align-items: center;
      pointer-events: none;
      opacity: 0.5;
    }
    
    .button-container {
      position: relative;
      top: 15px;
    }
    
    .button-group {
      position: absolute;
      padding-top:20px;
      bottom: 20px;
      right: 20px;
      display: flex;
      gap: 50px;
    }
    
    .responsive-div-import-enabled-button {
      display: inline-flex;
      align-items: center;
    }  

    .responsive-div-mie {
      padding-top:20px;
      height: 72vh;
      width: 100%;
      margin-left:5px;
    }
  
    .responsive-div-mie-table{
      width:100%; 
      max-height:65vh;
      overflow:auto;
      padding-top: 0px;
    }
  
    .responsive-div-mame {
      height: 75vh;
      }
  
      .responsive-div-mame-table {
        margin-top: 10px;
        width:100%; 
        height:63vh;
        overflow:auto;
        }

    .responsive-div-cmame-table {
      margin-top: 10px;
      width:100%; 
      height:25vh;
      overflow:auto;
      }
  
    .responsive-div-rec{
      height: 75vh;
    }

    .responsive-div-rec-reset{
      height: 85vh;
    }

    .responsive-div-rec-reset-typo{
      padding-top: 300px;
    }

    .responsive-div-rec-reset-button {
      position: absolute;
      right: 1%;
      top: 96%;
      transform: translateY(-50%);
      text-align: right;
      padding: 20px;
      margin: 0;
    }
  
    .responsive-div-rec-table {
      width:96%;
      max-height:50vh;
      overflow:hidden auto;
      margin-top:12px;
    }

}


/* zoom on 67% */
@media (min-width: 2100px) and (max-width: 2559.98px) {
  .responsive-div {
      padding: 45px;
      font-size: 2.7rem;
      max-width: 1800px;
      min-height: 83vh;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  
  .responsive-div-fileupload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
  }
  
  .sectionIcon {
      font-size: 120px;
      color: #808080;
      opacity: 0.5;
      margin-bottom: 10px;
  }
  
  .sectionPara {
      font-size: 30px;
      color: #808080;
      opacity: 0.5;
  }
  
  .responsive-div-table {
      width: 95%;
      overflow-y: auto;
      flex-grow: 1;
      margin-top: 10px;
      height: auto;
  }  

    .responsive-div-import-disabled-button {
        display: inline-flex;
        align-items: center;
        pointer-events: none;
        opacity: 0.5;
    }

    .button-container {
      position: relative;
      top: 5px;
    }

    .button-group {
      position: absolute;
      bottom: 10px;
      right: 20px;
      display: flex;
      gap: 50px;
    }

    .responsive-div-import-enabled-button {
      display: inline-flex;
      align-items: center;
    }

    .responsive-div-mie {
      height: 75vh;
      width: 100%;
      margin-left:5px;
    }

    .responsive-div-mie-table{
      width:100%; 
      max-height:66vh;
      overflow:auto;
      padding-top: 0px;
    }

    .responsive-div-mame {
      height: 78vh;
      }

      .responsive-div-mame-table {
        margin-top: 10px;
        width:100%; 
        height:67vh;
        overflow:auto;
        }
        
    .responsive-div-cmame-table {
      margin-top: 10px;
      width:100%; 
      height:22vh;
      overflow:auto;
      }
      
    .responsive-div-rec{
      height: 78vh;
    }

    .responsive-div-rec-reset{
      height: 86vh;
    }

    .responsive-div-rec-reset-typo{
      padding-top: 350px;
    }

    .responsive-div-rec-reset-button {
      position: absolute;
      right: 1%;
      top: 96%;
      transform: translateY(-50%);
      text-align: right;
      padding: 20px;
      margin: 0;
    }

    .responsive-div-rec-table {
      width:97%;
      max-height:55vh;
      overflow:hidden auto;
      margin-top:12px;
    }
}

/* zoom on 50% */
@media (min-width: 2560px) and (max-width: 4607.98px){
  /* Import Files screen */
  .responsive-div {
    padding: 45px;
    font-size: 2.7rem;
    max-width: 1800px;
    min-height: 87vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.responsive-div-fileupload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
}

.sectionIcon {
    font-size: 120px;
    color: #808080;
    opacity: 0.5;
    margin-bottom: 10px;
}

.sectionPara {
    font-size: 30px;
    color: #808080;
    opacity: 0.5;
}

.responsive-div-table {
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
    margin-top: 10px;
    height: auto;
}  

  .responsive-div-import-disabled-button {
      display: inline-flex;
      align-items: center;
      pointer-events: none;
      opacity: 0.5;
  }

  .button-container {
    position: relative;
    top: 5px;
  }

  .button-group {
    position: absolute;
    bottom: 10px;
    right: 20px;
    display: flex;
    gap: 50px;
  }

  .responsive-div-import-enabled-button {
    display: inline-flex;
    align-items: center;
  }
/* end */

/* manage import exception screen */
.responsive-div-mie {
    height: 80vh;
    width: 100%;
    margin-left:5px;
  }

  .responsive-div-mie-table{
    width:100%; 
    max-height:70vh;
    overflow:auto;
    padding-top: 0px;
  }
  /* end */

  /* manage auto match exception screen */
  .responsive-div-mame {
    height: 83vh;
    }

    .responsive-div-mame-table {
      margin-top: 10px;
      width:100%; 
      height:70vh;
      overflow:auto;
      }

  .responsive-div-cmame-table {
    margin-top: 10px;
    width:100%; 
    height:29vh;
    overflow:auto;
    }
/* end */

/* reconcile screen */
  .responsive-div-rec{
    height: 82vh;
  }

  .responsive-div-rec-reset{
    height: 90vh;
  }

  .responsive-div-rec-reset-typo{
    padding-top: 500px;
  }

  .responsive-div-rec-reset-button {
    position: absolute;
    right: 1%;
    top: 97%;
    transform: translateY(-50%);
    text-align: right;
    padding: 20px;
    margin: 0;
  }

  .responsive-div-rec-table {
    width:98%;
    max-height:60vh;
    overflow:hidden auto;
    margin-top:12px;
  }
  /* end */
}


/* zoom on 33% */
/* @media (min-width: 4608px) { */
  @media (min-width: 4608px)  and (max-width: 6144.98px) {
  /* Import Files screen */
  .responsive-div {
    padding: 45px;
    font-size: 2.7rem;
    max-width: 1800px;
    min-height: 89vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsive-div-fileupload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
  }

  .sectionIcon {
      font-size: 120px;
      color: #808080;
      opacity: 0.5;
      margin-bottom: 10px;
  }

  .sectionPara {
      font-size: 30px;
      color: #808080;
      opacity: 0.5;
  }

  .responsive-div-table {
      width: 100%;
      overflow-y: auto;
      flex-grow: 1;
      margin-top: 10px;
      height: auto;
  }
  .responsive-div-import-disabled-button {
    display: inline-flex;
    align-items: center;
    pointer-events: none;
    opacity: 0.5;
  }

  .button-container {
  position: relative;
  }

  .button-group {
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: flex;
  gap: 50px;
  }

  .responsive-div-import-enabled-button {
  display: inline-flex;
  align-items: center;
  }

  /* end */

  /* manage import exception screen */
  .responsive-div-mie {
    height: 85vh;
    width: 100%;
    margin-left:5px;
  }

  .responsive-div-mie-table{
    width:100%; 
    max-height:70vh;
    overflow:auto;
    padding-top: 0px;
  }
  /* end */

  /* manage auto match exception screen */
  .responsive-div-mame {
    height: 88vh;
    }

    .responsive-div-mame-table {
      margin-top: 10px;
      width:100%; 
      height:70vh;
      overflow:auto;
      }

  .responsive-div-cmame-table {
    margin-top: 10px;
    width:100%; 
    height:36vh;
    overflow:auto;
    }
/* end */

/* reconcile screen */
  .responsive-div-rec{
    height: 88vh;
  }

  .responsive-div-rec-reset{
    height: 93vh;
  }

  .responsive-div-rec-reset-typo{
    padding-top: 500px;
  }

  .responsive-div-rec-reset-button {
    position: absolute;
    right: 1%;
    top: 97%;
    transform: translateY(-50%);
    text-align: right;
    padding: 20px;
    margin: 0;
  }

  .responsive-div-rec-table {
    width:98%;
    max-height:60vh;
    overflow:hidden auto;
    margin-top:12px;
  }
  /* end */
}