input {
    border: 1px solid #999;
    padding: 0.5rem;
  }
  
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
    position: absolute;
    top:133px;
    background-color: white;
    width: 275px;
    z-index: 444;
    border: 1px solid gray;
  }
  
  .suggestions {
    margin-top: 0;
    max-height: 300px;
    padding-left: 0;
    overflow: auto;
    position: absolute;
    top:133px;
    background-color: white;
    width: 500px;
    z-index: 4000;
    border: 1px solid gray;
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: lightgrey;
    color: rgb(45, 184, 194);
    cursor: pointer;
    font-weight: 700;
    border: 1px solid rgba(0, 103, 181, 1)
  }
  