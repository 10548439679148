.moneysuggest {
    margin-top: 0;
    max-height: 300px;
    padding-left: 0;
    overflow: auto;
    position: absolute;
    top:52px;
    background-color: white;
    width: 409px;
    z-index: 4000;
    border: 1px solid gray;
  }
  
.moneysuggest li {
    padding: 0.5rem;
  }
  
.moneysuggest-active,
.moneysuggest li:hover {
    background-color: lightgrey;
    color: rgb(45, 184, 194);
    cursor: pointer;
    font-weight: 700;
    border: 1px solid rgba(0, 103, 181, 1)
  }
  