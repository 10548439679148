@import "normalize.css";

* {
  outline: none !important;
}

html {
  font-size: 62.5%;
  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
}
.MuiDrawer-paperAnchorRight-93 {
  top: 65px;
}
.MuiDrawer-paperAnchorRight-2707 {
  top: 65px;
}
.MuiDrawer-paperAnchorRight-2633 {
  top: 65px;
}
.MuiDrawer-paperAnchorRight-3975 {
  top: 65px;
}

.MuiFormControl-root-431.MuiFormControl-marginNormal-432 {
  width: calc(100% - 40px);
  margin-top: 30px;
  margin-bottom: 30px;
  /* margin: 20px; */
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role="button"] {
  text-decoration: none;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

@import "print.css";

@import "tables.css";

@import "react-table.css";

@import "prism.css";

@import "fuse-helpers.css";

@import "app.css";
