.minimal {
    padding: .3125rem 3rem .3125rem 1rem;
    background-image: url('/assets/images/icons/down_arrow.png');
    background-repeat: no-repeat, repeat;
    background-position: right 0.6rem center;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #000000;
    color: #000000;
    background-size: 12px 13px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.075);
    outline:none;
  }

  .minimalgray {
    padding: .3125rem 3rem .3125rem 1rem;
    background-image: url('/assets/images/icons/down_gray.png');
    background-repeat: no-repeat, repeat;
    background-position: right 0.6rem center;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: #000000;
    background-size: 12px 13px;
    background-color: #fff;
    outline:none;
  }